import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
//import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const Breadcrumb = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
         <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
          Scholastic
          </Typography>          
        </Box>
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Box marginBottom={3}
            href="/facilities"
          >
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
            Scholastic<br />
            </Typography>
            
            {/* <Typography> */}
              <Typography align="justify">
              National Public School, Yeshwanthpur has grown into one of the progressive premier educational institutions since its inception in the year 2013. The strength of each section is limited to 32-34 to facilitate individual attention and care. The main focus of the school is to provide each student a stimulating and eco-friendly environment to strengthen their self-confidence and self-esteem to be a global citizen of the 21 century. We believe that the greatest wealth of the country is its healthy and contributing citizens. The school has formulated guidelines safeguarding students from cyber-crime, handling emergencies and disasters, retaining their physical, emotional and personal safety.
            <br/>The uniqueness of each student is nurtured by providing exposure and opportunities in both scholastic and co- curricular areas with an emphasis on character building. The curriculum is also enhanced to reinforce integration across academic disciplines, helping students make connections within and outside the classroom. The school is consistently striving to be an institution of global excellence and ensures to make the process of learning enriching, effective and experiential.
            <br/>We encourage Parents' involvement in the development of their ward through regular interaction. The Orientation Programmes conducted for parents at the beginning of the academic year apprise them of the school policies and acquaints them with the educational pattern followed. In addition, regular circulars and online updates through Thursday Folders are issued to the parents informing them about the upcoming school events and the school policies.
            </Typography>
            
          </Box>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
           EARLY LEARNING PROGRAMMES
            </Typography>
            <Typography variant='h5' fontWeight={800}>
            Pre-Primary</Typography><br/>
            <Typography>
The Pre-Primary wing of National Public School Yeshwanthpur comprises of I-5 Academy - Montessori and Kindergarten streams as part of Early Childhood Education. Both work on the shared principles of stimulating young minds through inquiry. While Montessori students learn through exploration and discovery and take the path of a deductive approach to concretise the learning; the Kindergarten students use play way method of learning through group interactions and instructional methods. In both the approaches, we believe in learning through curiosity by constantly encouraging the young ones to boldly ask questions and explore deeper into concepts.
 This section is a child’s first step towards the education world. Here, we believe that children learn best in a nurturing environment, which is equally safe, enjoyable and brilliantly challenging. We regularly update the curriculum and methodology to keep pace with changes in educational reforms and research.
<br/><br/>Key Highlights<br/>
•	Concept Development<br/>
•	Formal Work Preparation<br/>
•	Motor Activities<br/>
•	Creative Arts<br/>
•	Role-play and dramatics<br/>

            </Typography>
            <br/>
            <Typography align="justify" fontWeight={800} variant='h5'>
            MONTESSORI PROGRAMME</Typography>
            <Typography>
            <br/>
            Montessori environments focus on self-directed activities and promote learning with hands on experience. Students perform different kinds of activities that appeal to their hands and senses as they go through the process of experiential learning.
            <br/>Individual and social development is fostered in each child by a unique way where mixed age groups are in the same environment i.e. of 3-6 years old children. Each level has an age appropriate curriculum. The activities presented to the beginners are preliminary and basic that helps them to settle down, familiarise themselves and to understand the norms of the environment.
            <br/>
            The four areas of work in the Montessori method encompasses the basic ability of the young students to improve their motor co-ordination and to enhance sensorial impressions, along with strengthening concepts in Language and Arithmetic. The sensorial activities are presented to the students which provide an opportunity to become aware of the different colours, shapes, sounds, textures and their different intensities. The Language activities begin at very early by way of conversation and enrichment of vocabulary. Sound recognition in a word enables children to understand that words are made of articulate sounds. Arithmetic activities begin around the age of 3.5 years as children at this age want to know 'how much', or 'how big'.
            </Typography>
            
            <Typography variant={'h5'} fontWeight={700} gutterBottom>  
              <br />KINDERGARTEN PROGRAMME
            </Typography>
            
            <Typography align="justify">
            At Pre-primary, the educational experience is focussed towards developing a sense of curiosity and inquisitiveness in a child. We believe in the collaborative approach to education. Each child's individual contributions are valued and cherished in a stimulating and safe environment fostering them to explore and to express their ideas with confidence.
            <br/>
            Kindergarten is a three year programme. The medium of instruction is English and students are taught English language through the phonetic method. They get an exposure to the basic mathematical concepts. Integrated learning is an integral part of teaching. Along with motor refinement during outdoor activities, cognitive development is instilled through field trips and story narration sessions. Teachers monitor the progress of students using innovative teaching aids as resource materials.
            </Typography>
           
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
            
              <br /> PRIMARY LEARNING PROGRAMME
            </Typography>
            
            <Typography align="justify">
            The Primary Learning programme is committed to imparting a strong foundation to students of Classes 1-5, in various disciplines such as English, Mathematics, Social Science, Life Science, Language Studies, Environment Science and Information Technology. We provide a challenging academic curriculum, coupled with a very supportive group of facilitators who value the strengths and talents of the individual student and inculcate in them the spirit of enquiry.
            <br/>
            The curriculum builds on the spirit of exploration. Emphasis is laid on acquiring sound language skills, clarity of mathematical concepts and the discovery and understanding of scientific principles. Here the focus is to develop confidence, to build self-esteem, to expose students to different aspects of learning and to enhance their communication skills. Evaluation of each student's progress is done through Monthly Reviews.
            <br/>
            In order to remove the tedium in classroom, a wide range of classroom techniques of teaching are used - such as, group discussions, projects and several unique resources like 'do and learn' technique. This makes the entire learning process a thoroughly enjoyable one. Students start using Maths Lab from Class 1 which will help them sharpen their analytical skills and Junior Science Lab providing hands on experience in learning. Additional Languages Kannada/Hindi is introduced from Class 1 and Sanskrit is introduced as 3rd from Class 5 onwards.
            </Typography>
           
            
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              <br />
            MIDDLE SCHOOL LEARNING PROGRAMME
            </Typography>
           
            <Typography align="justify">
            The Middle School years, has the added responsibility of grooming up the personality of each student. The emphasis is on investigation and conceptualization through sharpening their analytical and writing skills. In Classes 6-8 the programme is designed as per CBSE curriculum with focus on interactive and hands on experience sessions.
            <br/>During these years, students are prepared to tackle different subjects along with learning the challenges of an additional Language. English, Mathematics, Social Science and Science are the core subjects. Apart from these, Subject Enrichment Activities, educational field trips, presentations of projects and class discussions are conducted to strengthen their awareness. Activities and experiments are conducted in the Junior Science Lab, Junior Computer Lab and the Maths Lab. The students get adequate exposure to remain updated in this tech savvy world
            </Typography>
           
            
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              <br />SENIOR SCHOOL LEARNING PROGRAMME
            </Typography>
            
            
            <Typography align="justify">
            We offer a broad and balanced curriculum which is designed to match the educational needs of the students within the requirements of the CBSE. Our objective is to equip the students with knowledge, skills and values which will enable them to face the Board Examination with confidence and at ease. Students gain experience in life skills for their personal and professional development that enable them to grow into young adults who are socially aware of their responsibilities towards the society.
<br/>A carefully planned academic syllabus in classes 9 and 10 ensures that the student enjoys the years spent in learning and acquires a sound understanding of concepts in Mathematics, Social Science, Science, Computer Science and Languages. Activities and experiments are conducted in the Science Labs, the Maths Lab and the Computer Lab strengthening their practical knowledge.
<br/>The syllabus is enriched by teacher designed worksheets and subject integrated projects. Emphasis is placed on learning through curriculum related field trips, talks by invited eminent speakers and presentations. Apart from these, Subject Enrichment Activities are also conducted. The school updates its curriculum annually to keep up with the advancements in technology and modification in CBSE syllabus

            </Typography>
            
            <Typography  componen='p' fontWeight={700} gutterBottom>
              SENIOR SECONDARY SCHOOL LEARNING PROGRAMME
            </Typography>
            
            
            <Typography align="justify">
            We offer the following streams of study in Classes 11 and 12
            <br/><br/>
           <strong> Science Stream : </strong> <br/>
            English, Physics, Chemistry, Mathematics / Biology, Computer Science / Economics / Physical Education. <br/>
            English, Physics, Chemistry, Biology, Informatics Practices / Physical Education. <br/><br/>
            <strong>Commerce Stream : </strong>  <br/>
           English, Accountancy, Business Studies, Economics, Applied Mathematics / Informatics Practices / Physical Education. <br/><br/>
           <strong>Humanities Stream : </strong>  <br/>
           English, History, Political Science, Economics, Applied Mathematics / Informatics Practices / Physical Education.


            
           
           <br/><br/>
           The course is intended to provide students a high level of competence through extensive exposure in the different subjects through practical experiments, presentations, discussions and interaction with renowned resource personnel. Practical Classes organised in the different Labs provide students good exposure to practise the theoretical concepts.
<br/>We focus in developing proficiency, competence and analytical ability in each subject along with inculcating a taste for research and extra reading with discernment and delight, promoting each child's receptive and productive skills. The students are exposed to different career options to help them decide a niche for themselves in this competitive world.

           </Typography>
           
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
              </Box>
            </Grid>
            <Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                <Box>

                  {/* <Box
                    component={Button}
                    variant="outlined"
                    color="primary"
                    size="large"
                    marginRight={2}
                    href="/scholastic"
                  >
           Scholastic
                  </Box> */}
                </Box>
                {/* <Box
                  component={Button}
                  variant="outlined"
                  color="primary"
                  size="large"
                  marginLeft={4}
                  href="/co-scholastic"
                >
           Co-Scholastic
                </Box> */}
              </Box>
            </Box>  
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};



export default Breadcrumb;