import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Breadcrumb, Footer , Categories, Questions,} from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const HelpCenterArticle = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container maxWidth={0.9} marginTop={-5} >
        <Footer />
      </Container>
      <Box >
        <Container paddingY={`${theme.spacing(2)} !important`}>
          <Breadcrumb />
        </Container>
      </Box>
      <Box>
        
        </Box>
      {/* <Container>
        <Questions />
      </Container>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Container>
          <Categories />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box> */}
    </Main>
  );
};

export default HelpCenterArticle;
